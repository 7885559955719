import {
  Sidebar,
  SidebarBottom,
  SidebarButton,
  SidebarButtonLogo,
  SidebarUserMenu,
  UIAppLayout,
  UIContent,
  useAuth,
  useTheme,
} from "@eventsquare/uikit";
import { Link, Outlet, useLocation } from "react-router-dom";

import {
  RiCoupon2Line,
  RiLockStarLine,
  RiMailSendLine,
  RiMoonLine,
  RiShoppingCartLine,
  RiSunLine,
} from "@remixicon/react";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const AppLayout = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { t, i18n } = useTranslation("app_layout");

  const { colorMode, setColorMode } = useTheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggleTheme = () => {
    setColorMode(colorMode === "light" ? "dark" : "light");
  };

  // Toggle color mode on keypress $
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.code === "BracketRight") {
        handleToggleTheme();
      }
    };
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [handleToggleTheme]);

  useEffect(() => {
    if (!user) return;
    const lang = user?.language;
    const supportedLanguages = ["en", "nl", "fr"];
    if (lang && supportedLanguages.includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [user?.language]);

  return (
    <UIAppLayout>
      <Sidebar variant="pos">
        <Link to="/">
          <SidebarButtonLogo />
        </Link>
        <Link to="/">
          <SidebarButton
            icon={RiShoppingCartLine}
            label={t("sidebar.channels")}
            active={
              location.pathname === "/" ||
              location.pathname.includes("/channel")
            }
          />
        </Link>
        <Link to="/orders">
          <SidebarButton
            icon={RiCoupon2Line}
            label={t("sidebar.orders")}
            active={location.pathname === "/orders"}
          />
        </Link>
        <Link to="/invitations">
          <SidebarButton
            icon={RiMailSendLine}
            label={t("sidebar.invitations")}
            active={location.pathname === "/invitations"}
          />
        </Link>
        <Link to="/carts">
          <SidebarButton
            icon={RiLockStarLine}
            label={t("sidebar.carts")}
            active={location.pathname === "/carts"}
          />
        </Link>
        <SidebarBottom>
          <SidebarUserMenu
            translations={{
              switch_account: t("sidebar.user_menu.switch_account"),
              logout: t("sidebar.user_menu.logout"),
              change_avatar: t("sidebar.user_menu.change_avatar"),
              switch_language: t("sidebar.user_menu.switch_language"),
              languages: {
                nl: t("sidebar.user_menu.languages.nl"),
                en: t("sidebar.user_menu.languages.en"),
                fr: t("sidebar.user_menu.languages.fr"),
              },
            }}
          />
          <SidebarButton
            icon={colorMode === "light" ? RiSunLine : RiMoonLine}
            label={
              colorMode === "light"
                ? t("sidebar.toggleDarkMode")
                : t("sidebar.toggleLightMode")
            }
            onClick={handleToggleTheme}
          />
        </SidebarBottom>
      </Sidebar>
      <UIContent>
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </UIContent>
    </UIAppLayout>
  );
};
